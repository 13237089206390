<template>
  <div id="wizard-view">
    <b-modal
      id="occasion-modal"
      :no-close-on-backdrop="true"
      class="modal-dialog modal-dialog-centered modal-lg"
      scrollable
      ref="my-modal"
      hide-footer
      size="lg"
    >
      <section class="">
        <div id="select-occasion" class="mt-2">
          <b-overlay :show="loader" rounded="sm">
            <div class="container mb-3 ">
              <center>
                <h3 for="">
                  <strong class="text-danger my-3"></strong>SELECT ONE OCCASION
                </h3>
              </center>
              <label class="wrap my-5 col-md-12">
                <select
                  name=""
                  class="form-control noBorder"
                  id=""
                  v-model="occasion"
                >
                  <option value="">--Select Occasion--</option>
                  <option :value="occasion" v-for="occasion in occasions">{{
                    occasion.occasion_name
                  }}</option>
                </select>
              </label>
              <span v-if="button" class="text-danger"
                >*Please select an Occasion</span
              >
              <div class="mt-3 mb-3" slot="modal-footer">
                <div class="row d-flex justify-content-between">
                  <div class="col-md-4 "></div>
                  <div class="col-md-4">
                    <button
                      class="btn btn-danger float-right col-md-6"
                      @click="next"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <template #overlay>
              <div class="text-center">
                <img
                  src="@/assets/site/images/Govava_Wait_icon.gif"
                  alt=""
                  class=""
                />
                <p id="cancel-label">Please wait...</p>
              </div>
            </template>
          </b-overlay>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import SelectOccasion from "@/views/site/wizard/SelectOccasion";

export default {
  name: "OccasionModal",
  props: ["type"],
  data() {
    return {
      loader: false,
      occasion: "",
      occasions: [],
      button: false,
    };
  },
  components: {
    SelectOccasion,
  },
  created() {
    if (this.type == "pet") {
      this.getPetOccasions();
    } else {
      this.getOccasions();
    }
  },
  methods: {
    getOccasions() {
      this.loader = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/occasion/getOccasions";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.occasions = data.occasions;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getPetOccasions() {
      this.loader = true;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/getAllOccasions";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.occasions = data.allOccasions;
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      if (this.occasion == "") {
        this.button = true;
      } else {
        this.$emit("savetoWishlist", this.occasion);
      }
    },
  },
};
</script>

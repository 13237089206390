<template>
  <div id="calmed-product">
    <img class="product-image-photo " :src="getImgUrl()" alt />
    <h5 class="text-center product-head text-black">{{ product.name }}</h5>
    <div class="text-black">
      {{ product.details_stub }}
      <router-link :to="`/breast_pumps/pump_details/${product.slug}`"
        >more info</router-link
      >
    </div>
    <button @click="applyProduct()" class="btn btn-primary px-4 my-2">
      Apply Now
    </button>
  </div>
</template>
<script>
export default {
  name: "Product",
  props: ["wizardProducts"],
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: `${this.product.name} ${this.product.details_stub}`,
        },
        {
          name: "keywords",
          content: this.product.name,
        },
      ],
    };
  },
  methods: {
    getImgUrl() {
      return (
        process.env.VUE_APP_URL +
        "storage/uploads/" +
        this.product.slug +
        "/" +
        this.product.image
      );
    },
    applyProduct() {
      this.$emit("apply-clicked", this.product);
    },
  },
};
</script>
